import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const ZlotyUlanKaluszynPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Okuniew-Zabraniec, Krzyż Pamiątkowy Między Okuniewem a Zabrańcem" />
      <ReturnToPrevPage to={'/gminy/halinow'}>
        Materiały dotyczące gminy Halinów
      </ReturnToPrevPage>
      <Header1>
        Okuniew-Zabraniec, Krzyż Pamiątkowy Między Okuniewem a Zabrańcem
      </Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          W 1929 roku państwo Jan i Ewa Strzelec na wydmie znajdującej się na
          północ od Okuniewa, przy drodze do wsi Zabraniec ustawili pamiątkowy
          krzyż. Upamiętnia od walki w bolszewikami w 1920 roku, które toczyły
          się w tej okolicy, o czym świadczą pozostałe do dziś ślady okopów.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/halinow/krzyz-okuniew-zabraniec/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/halinow/krzyz-okuniew-zabraniec/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ZlotyUlanKaluszynPage
